<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.order") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="flex h-full flex-col">
        <h2 class="mb-2">{{ t("headings.info") }}</h2>
        <div class="card p-4">
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label class="block">{{ t("labels.id") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ order.id }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.orderer") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ user.lastName }} {{ user.firstName }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.created_at") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ getLocaleDate(locale, order.createdAt) }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.price") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ order.price / 100 }}</span>
            </div>
            <div>
              <label class="block">{{ t("labels.delivered") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                order.delivered ? t("labels.yes") : t("labels.no")
              }}</span>
            </div>
          </div>
          <hr class="my-4 border-slate-300" />
          <div class="flex justify-end">
            <form-button
              :command="() => deliver(order.id)"
              :disabled="waiting || order.delivered"
              id="order-deliver-button"
              label="mark_as_delivered"
              :textVariant="company.primaryText || 'light'"
              type="button"
              :variant="company.primary || 'indigo'"
            />
          </div>
        </div>
      </div>
      <div class="flex h-full flex-col">
        <h2 class="mb-2">{{ t("headings.products") }}</h2>
        <div class="card p-4">
          <custom-table
            :fields="fields"
            :items="order.products ? order.products : []"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="sortFields"
            @row-click="() => null"
          >
            <template #cell(price)="{ value }">
              {{ value / 100 }}
            </template>
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const order = computed(() => store.state.order.order);
    const user = computed(() => store.state.user.user);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.order.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "price",
        label: "price",
      },
      {
        key: "estimatedDelivery",
        label: "estimated_delivery",
      },
    ]);
    const searchFields = ref(["name"]);
    const sortFields = ref(["name", "price"]);

    onMounted(() => {
      if (!order.value.id || order.value.id != route.params.orderId) {
        store.dispatch("order/getOrder", route.params.orderId);
      } else {
        store.dispatch("estate/getEstate", order.value.estateId);
        store.dispatch("user/getUser", order.value.userId);
      }
    });

    watch(
      () => order.value,
      (order) => {
        if (order.id) {
          store.dispatch("estate/getEstate", order.estateId);
          store.dispatch("user/getUser", order.userId);
        }
      }
    );

    return {
      company,
      deliver: (id) => store.dispatch("order/deliverOrder", id),
      estate,
      fields,
      getLocaleDate,
      locale,
      order,
      router,
      searchFields,
      sortFields,
      t,
      user,
      waiting,
    };
  },
};
</script>
